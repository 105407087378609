<template>
    <div>
        <div class="row" v-if="isDataSet">
            <div class="col-lg-3 col-md-5 col-sm-12">
                <ts-panel>
                    <div id="cover-image">
                        <img
                            :src="require('@/assets/cover.png')"
                            alt
                            class="cover"
                        />
                        <div id="img-frame" class="text-center mb-1">
                            <img
                                v-lazy="{
                                    src: photoCus(record.customer),
                                    error:
                                        record.customer.gender == 'Female'
                                            ? require('@/assets/staff-default-women.png')
                                            : require('@/assets/staff-default-man.png'),
                                    loading: require('@/assets/Rolling-1s-200px.svg'),
                                    preLoad: 1.3,
                                    attempt: 1
                                }"
                            />
                        </div>
                    </div>
                    <div class="row m-b mb-1-15">
                        <div class="col-md-12 text-center">
                            <span>{{
                                record.customer
                                    ? record.customer.customer_code
                                    : "-"
                            }}</span>
                            <h3
                                class="text-center ts-font-bold ts-text-lg mt-2 mb-0 text-uppercase text-primary"
                            >
                                {{
                                    record.customer
                                        ? record.customer.customer_name_en
                                        : "-"
                                }}
                            </h3>
                            <small class="text-center text-default">
                                -
                            </small>
                        </div>
                    </div>
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t("customerLoanRequest.nameKh") }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    record.customer
                                        ? record.customer.customer_name_kh
                                        : "-"
                                }}
                            </div>
                        </a>
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t("customerLoanRequest.sex") }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    record.customer
                                        ? record.customer.gender
                                        : "-"
                                }}
                            </div>
                        </a>
                        <a href="#" class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t("customerLoanRequest.dateOfBirth") }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    record.customer
                                        ? record.customer.date_of_birth
                                        : "-"
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                </ts-panel>
                <br />
                <!-- begin b-card -->
                <b-card no-body>
                    <b-card-header
                        header-tag="header"
                        class="card-header pointer-cursor collapsed"
                        aria-expanded="false"
                        v-b-toggle.addressContact
                    >
                        {{ $t("customerLoanRequest.addressContact") }}
                    </b-card-header>
                    <b-collapse
                        id="addressContact"
                        visible
                        accordion="my-accordion"
                    >
                        <b-card-body>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item address">
                                    <span class="font-bold"
                                        ><i
                                            class="fas fa-map-marker-alt m-r-5"
                                        ></i>
                                        {{
                                            $t("customerLoanRequest.address")
                                        }}</span
                                    >
                                    <br />
                                    <span v-if="locale === 'en'"
                                        >{{
                                            record.customer
                                                ? "House # " +
                                                  record.customer.house_no +
                                                  ", "
                                                : "-"
                                        }}
                                        {{
                                            record.customer
                                                ? "Street # " +
                                                  record.customer.street_no +
                                                  ", "
                                                : "-"
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.vil_name_en
                                                    ? record.customer
                                                          .vil_name_en + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.com_name_en
                                                    ? record.customer
                                                          .com_name_en + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.dis_name_en
                                                    ? record.customer
                                                          .dis_name_en + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.pro_name_en
                                                    ? record.customer
                                                          .pro_name_en + "."
                                                    : "-"
                                                : "-"
                                        }}
                                    </span>
                                    <span v-else>
                                        {{
                                            record.customer
                                                ? "លេខផ្ទះ # " +
                                                  record.customer.house_no +
                                                  ", "
                                                : "-"
                                        }}
                                        {{
                                            record.customer
                                                ? "លេខផ្លូវ # " +
                                                  record.customer.street_no +
                                                  ", "
                                                : "-"
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.vil_name_kh
                                                    ? record.customer
                                                          .vil_name_kh + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.com_name_kh
                                                    ? record.customer
                                                          .com_name_kh + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.dis_name_kh
                                                    ? record.customer
                                                          .dis_name_kh + ", "
                                                    : ""
                                                : ""
                                        }}
                                        {{
                                            record.customer
                                                ? record.customer.pro_name_kh
                                                    ? record.customer
                                                          .pro_name_kh + "."
                                                    : "-"
                                                : "-"
                                        }}
                                    </span>
                                </li>
                                <li class="list-group-item address mt-2">
                                    <span class="font-bold"
                                        ><i class="fas fa-phone m-r-5"></i>
                                        {{
                                            $t("customerLoanRequest.contactNo")
                                        }}</span
                                    >
                                    <br />
                                    <small>
                                        {{
                                            record.customer
                                                ? record.customer.contact_no
                                                    ? record.customer.contact_no
                                                    : "-"
                                                : "-"
                                        }}
                                    </small>
                                </li>
                            </ul>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- end b-card -->
            </div>
            <div class="col-lg-9 col-md-7 col-sm-12">
                <div class="overflow-auto" style="max-height: 60vh;">
                    <!-- begin Cutomer Information b-card -->
                    <b-card no-body class="mb-2">
                        <b-card-header
                            header-tag="header"
                            class="card-header pointer-cursor collapsed"
                            :expanded="
                                record.approve_status.approve_status_id == 0
                            "
                            v-b-toggle.customerInformation
                        >
                            {{ $t("customerLoanRequest.customerInformation") }}
                        </b-card-header>
                        <b-collapse
                            id="customerInformation"
                            :visible="
                                record.approve_status.approve_status_id == 0
                            "
                            accordion="customerInformation"
                        >
                            <b-card-body>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.documentType"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? locale == "kh"
                                                            ? record.customer
                                                                  .doc_type_kh
                                                            : record.customer
                                                                  .doc_type_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nidOrpassportIssuedDate"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .issued_date
                                                            ? record.customer
                                                                  .issued_date
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.occupation"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .occupation_code
                                                            ? record.customer
                                                                  .occupation_code +
                                                              "|" + (
                                                                  locale == "kh"
                                                                      ? record
                                                                            .customer
                                                                            .occupation_kh
                                                                      : record
                                                                            .customer
                                                                            .occupation_en
                                                              )
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <h5
                                                class="col-md-4 form-col-label control-label tw-text-right text-primary"
                                            >
                                                &diams;
                                                <u>{{
                                                    $t(
                                                        "customerLoanRequest.spouse"
                                                    )
                                                }}</u>
                                            </h5>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nameKh"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .spouse_name_kh
                                                            ? record.customer
                                                                  .spouse_name_kh
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.sex"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .sp_gender
                                                            ? record.customer
                                                                  .sp_gender
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.documentType"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? locale == "kh"
                                                            ? record.customer
                                                                  .sp_doc_type_kh
                                                            : record.customer
                                                                  .sp_doc_type_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.occupation"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .sp_occupation_code
                                                            ? record.customer
                                                                  .sp_occupation_code +
                                                              "|" + (
                                                                  locale == "kh"
                                                                      ? record
                                                                            .customer
                                                                            .sp_occupation_kh
                                                                      : record
                                                                            .customer
                                                                            .sp_occupation_en
                                                              )
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <h5
                                                class="col-md-4 form-col-label control-label tw-text-right text-primary"
                                            >
                                                &diams;
                                                <u>{{
                                                    $t(
                                                        "customerLoanRequest.guarantor"
                                                    )
                                                }}</u>
                                            </h5>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nameKh"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .guarantor_name_kh
                                                            ? record.customer
                                                                  .guarantor_name_kh
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.relationship"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .relationship
                                                            ? record.customer
                                                                  .relationship
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nidOrpassport"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer.ssn_no
                                                            ? record.customer
                                                                  .ssn_no
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nidOrpassportExpiryDate"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .expiry_date
                                                            ? record.customer
                                                                  .expiry_date
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.email"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer.email
                                                            ? record.customer
                                                                  .email
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nameEn"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .spouse_name_en
                                                            ? record.customer
                                                                  .spouse_name_en
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.dateOfBirth"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .sp_date_of_birth
                                                            ? record.customer
                                                                  .sp_date_of_birth
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nidOrpassport"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .sp_ssn_no
                                                            ? record.customer
                                                                  .sp_ssn_no
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.contactNo"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .sp_contact_no
                                                            ? record.customer
                                                                  .sp_contact_no
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nameEn"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .guarantor_name_en
                                                            ? record.customer
                                                                  .guarantor_name_en
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.contactNo"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.customer
                                                        ? record.customer
                                                              .guarantor_phone
                                                            ? record.customer
                                                                  .guarantor_phone
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- end b-card -->
                    <!-- begin Loan Information b-card -->
                    <b-card no-body class="mb-2">
                        <b-card-header
                            header-tag="header"
                            class="card-header pointer-cursor collapsed"
                            :expanded="
                                record.approve_status.approve_status_id == 0
                            "
                            v-b-toggle.loanInformation
                        >
                            {{ $t("customerLoanRequest.loanInformation") }}
                        </b-card-header>
                        <b-collapse
                            id="loanInformation"
                            :visible="
                                record.approve_status.approve_status_id == 0
                            "
                            accordion="loanInformation"
                        >
                            <b-card-body>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.requestDate"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record
                                                        ? record.requested_date
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.requestNumber"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.request_number
                                                        ? record.request_number
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.loanAmount"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-success">
                                                <span
                                                    class="tw-font-bold tw-text-sm"
                                                    >{{
                                                        record
                                                            ? formatCurrencyWithCode(
                                                                  record.request_amount,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}</span
                                                >
                                                ({{
                                                    record
                                                        ? record.request_amount_in_word +
                                                          record.currency
                                                              .currency_name_kh
                                                        : ""
                                                }})
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.interestRate"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record
                                                        ? record.request_interest_rate +
                                                          "%"
                                                        : "-"
                                                }}
                                                ({{
                                                    record
                                                        ? record.request_interest_rate_in_word
                                                        : ""
                                                }})
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t("customerLoanRequest.co")
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.co
                                                        ? locale == "kh"
                                                            ? record.co
                                                                  .employee_name_kh
                                                            : record.co
                                                                  .employee_name_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.bmName"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.bm
                                                        ? locale == "kh"
                                                            ? record.bm
                                                                  .employee_name_kh
                                                            : record.bm
                                                                  .employee_name_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.purpose"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.purpose
                                                        ? locale == "kh"
                                                            ? record.purpose
                                                                  .purpose_kh
                                                            : record.purpose
                                                                  .purpose_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.loanType"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.loan_type
                                                        ? locale == "kh"
                                                            ? record.loan_type
                                                                  .loan_type_kh
                                                            : record.loan_type
                                                                  .loan_type_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.groupId"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.group_id
                                                        ? record.group_id
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.installmentType"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.installment_type
                                                        ? locale == "kh"
                                                            ? record
                                                                  .installment_type
                                                                  .name_kh
                                                            : record
                                                                  .installment_type
                                                                  .name_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.installmentTypeDuration"
                                                    )
                                                }}
                                                (T)
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.request_loan_duration
                                                        ? record.request_loan_duration
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.firstInstallmentAmount"
                                                    )
                                                }}
                                                (R)
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.first_installment_amount
                                                        ? formatCurrencyWithCode(
                                                              record.first_installment_amount,
                                                              record.currency
                                                          )
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- end b-card -->
                    <!-- begin Source of Income and Expenses b-card -->
                    <b-card no-body class="mb-2">
                        <b-card-header
                            header-tag="header"
                            class="card-header pointer-cursor collapsed"
                            aria-expanded="true"
                            v-b-toggle.sourceOfIncomeAndExpenses
                        >
                            {{
                                $t(
                                    "customerLoanRequest.sourceOfIncomeAndExpenses"
                                )
                            }}
                        </b-card-header>
                        <b-collapse
                            id="sourceOfIncomeAndExpenses"
                            :visible="false"
                            accordion="sourceOfIncomeAndExpenses"
                        >
                            <b-card-body>
                                <div class="table-ui-detail">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th width="30%">
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.sourceOfIncome"
                                                        )
                                                    }}
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-center"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.averageIncomePerMonth"
                                                        )
                                                    }}
                                                </th>
                                                <th width="30%">
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.sourceOfExpense"
                                                        )
                                                    }}
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-center"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.expenses"
                                                        )
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.source_of_income_1
                                                            ? record.source_of_income_1
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.average_imcome_1
                                                            ? formatCurrencyWithCode(
                                                                  record.average_imcome_1,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        record.source_of_expense_1
                                                            ? record.source_of_expense_1
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.expense_1
                                                            ? formatCurrencyWithCode(
                                                                  record.expense_1,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.source_of_income_2
                                                            ? record.source_of_income_2
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.average_imcome_2
                                                            ? formatCurrencyWithCode(
                                                                  record.average_imcome_2,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        record.source_of_expense_2
                                                            ? record.source_of_expense_2
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.expense_2
                                                            ? formatCurrencyWithCode(
                                                                  record.expense_2,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.source_of_income_3
                                                            ? record.source_of_income_3
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.average_imcome_3
                                                            ? formatCurrencyWithCode(
                                                                  record.average_imcome_3,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        record.source_of_expense_3
                                                            ? record.source_of_expense_3
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.expense_3
                                                            ? formatCurrencyWithCode(
                                                                  record.expense_3,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.source_of_income_3
                                                            ? record.source_of_income_3
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.average_imcome_3
                                                            ? formatCurrencyWithCode(
                                                                  record.average_imcome_3,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                        record.source_of_expense_3
                                                            ? record.source_of_expense_3
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.expense_3
                                                            ? formatCurrencyWithCode(
                                                                  record.expense_3,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th
                                                    width="30%"
                                                    class="tw-text-right text-info tw-font-bold"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.totalMonthlyIncome"
                                                        )
                                                    }}
                                                    (A):
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-right text-info tw-font-bold"
                                                >
                                                    {{
                                                        record.total_monthly_income
                                                            ? formatCurrencyWithCode(
                                                                  record.total_monthly_income,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </th>
                                                <th
                                                    class="tw-text-right text-warning tw-font-bold"
                                                    width="30%"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.totalMonthlyExpenses"
                                                        )
                                                    }}
                                                    (B):
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-right text-warning tw-font-bold"
                                                >
                                                    {{
                                                        record.total_monthly_expense
                                                            ? formatCurrencyWithCode(
                                                                  record.total_monthly_expense,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    width="100%"
                                                    class="text-success tw-font-bold"
                                                    colspan="4"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.netProfit"
                                                        )
                                                    }}
                                                    (C = A – B):
                                                    {{
                                                        formatCurrencyWithCode(
                                                            record.total_monthly_income -
                                                                record.total_monthly_expense,
                                                            record.currency
                                                        )
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- end b-card -->
                    <!-- begin Debts owed & Ability to borrow money b-card -->
                    <b-card no-body class="mb-2">
                        <b-card-header
                            header-tag="header"
                            class="card-header pointer-cursor collapsed"
                            aria-expanded="true"
                            v-b-toggle.debtsOwedAndAbilityToBorrowMoney
                        >
                            {{
                                $t(
                                    "customerLoanRequest.debtsOwedAndAbilityToBorrowMoney"
                                )
                            }}
                        </b-card-header>
                        <b-collapse
                            id="debtsOwedAndAbilityToBorrowMoney"
                            :visible="false"
                            accordion="debtsOwedAndAbilityToBorrowMoney"
                        >
                            <b-card-body>
                                <div class="table-ui-detail">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th width="30%">
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.institutions"
                                                        )
                                                    }}
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-center"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.loanDurationInMonth"
                                                        )
                                                    }}
                                                </th>
                                                <th
                                                    width="30%"
                                                    class="tw-text-center"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.monthlyPayment"
                                                        )
                                                    }}
                                                </th>
                                                <th
                                                    width="20%"
                                                    class="tw-text-center"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.outstandingBalance"
                                                        )
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.mfi_owe_due_1
                                                            ? record.mfi_owe_due_1
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_1_installment
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_1_installment,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td class="tw-text-center">
                                                    {{
                                                        record.mfi_owe_due_1_duration
                                                            ? record.mfi_owe_due_1_duration
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_1_outstanding
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_1_outstanding,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.mfi_owe_due_2
                                                            ? record.mfi_owe_due_2
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_2_installment
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_2_installment,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td class="tw-text-center">
                                                    {{
                                                        record.mfi_owe_due_2_duration
                                                            ? record.mfi_owe_due_2_duration
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_2_outstanding
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_2_outstanding,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{
                                                        record.mfi_owe_due_3
                                                            ? record.mfi_owe_due_3
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_3_installment
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_3_installment,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                                <td class="tw-text-center">
                                                    {{
                                                        record.mfi_owe_due_3_duration
                                                            ? record.mfi_owe_due_3_duration
                                                            : "-"
                                                    }}
                                                </td>
                                                <td
                                                    class="tw-text-right text-blue"
                                                >
                                                    {{
                                                        record.mfi_owe_due_3_outstanding
                                                            ? formatCurrencyWithCode(
                                                                  record.mfi_owe_due_3_outstanding,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th
                                                    width="50%"
                                                    colspan="2"
                                                    class="tw-text-right text-blue tw-font-bold"
                                                >
                                                    {{
                                                        $t(
                                                            "customerLoanRequest.totalDebtToBePaidMonthly"
                                                        )
                                                    }}
                                                </th>
                                                <th width="30%">
                                                    (D):
                                                    {{
                                                        record.total_owe_due_to_be_paid
                                                            ? formatCurrencyWithCode(
                                                                  record.total_owe_due_to_be_paid,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </th>
                                                <th width="20%">
                                                    (E):
                                                    {{
                                                        record.total_outstanding
                                                            ? formatCurrencyWithCode(
                                                                  record.total_outstanding,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div
                                    class="tw-flex tw-items-center mt-4 mb-3 tw-font-bold"
                                >
                                    <div class="tw-text-right">
                                        {{
                                            $t(
                                                "customerLoanRequest.disbursalAmount"
                                            )
                                        }}
                                        = (C x T x 70%) – E =
                                        <span class="text-blue">{{
                                            record.disbursal_amount
                                                ? formatCurrencyWithCode(
                                                      record.disbursal_amount,
                                                      record.currency
                                                  )
                                                : "-"
                                        }}</span>
                                    </div>
                                </div>
                                <div
                                    class="tw-flex tw-items-center tw-font-bold"
                                >
                                    <div class="tw-text-right">
                                        {{
                                            $t(
                                                "customerLoanRequest.installmentPossibility"
                                            )
                                        }}
                                        = ((C - D) ៖ (R x 2)) x 100 =
                                        <span class="text-blue"
                                            >{{
                                                record.first_installment_amount
                                                    ? record.installment_possibility
                                                    : "-"
                                            }}% (>=100%)</span
                                        >
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- end b-card -->
                    <!-- begin Loan Approved b-card -->
                    <b-card
                        no-body
                        class="mb-2"
                        v-if="record.approve_status.approve_status_id != 0"
                    >
                        <b-card-header
                            header-tag="header"
                            class="card-header pointer-cursor collapsed"
                            :expanded="
                                record.approve_status.approve_status_id == 2
                            "
                            v-b-toggle.loanApproval
                        >
                            {{ $t("customerLoanRequest.loanApproval") }}
                        </b-card-header>
                        <b-collapse
                            id="loanApproval"
                            :visible="
                                record.approve_status.approve_status_id == 2
                            "
                            accordion="loanApproval"
                        >
                            <b-card-body>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.amountApprove"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-success">
                                                <span
                                                    class="tw-font-bold tw-text-sm"
                                                    >{{
                                                        record
                                                            ? formatCurrencyWithCode(
                                                                  record.app_amount,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}</span
                                                >
                                                ({{
                                                    record
                                                        ? record.app_amount_in_word +
                                                          record.currency
                                                              .currency_name_kh
                                                        : ""
                                                }})
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.installmentType"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.app_installment_type
                                                        ? locale == "kh"
                                                            ? record
                                                                  .app_installment_type
                                                                  .name_kh
                                                            : record
                                                                  .app_installment_type
                                                                  .name_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <div
                                                        v-if="
                                                            record.app_installment_type_id ==
                                                                2
                                                        "
                                                    >
                                                        <ts-checkbox
                                                            v-model="
                                                                record.interval_1_week
                                                            "
                                                            disabled
                                                            >{{
                                                                $t(
                                                                    "customerLoanRequest.interval1Week"
                                                                )
                                                            }}</ts-checkbox
                                                        >
                                                    </div>
                                                    <div
                                                        v-if="
                                                            record.app_installment_type_id ==
                                                                2
                                                        "
                                                    >
                                                        <ts-checkbox
                                                            v-model="
                                                                record.interval_2_week
                                                            "
                                                            disabled
                                                            >{{
                                                                $t(
                                                                    "customerLoanRequest.interval2Week"
                                                                )
                                                            }}</ts-checkbox
                                                        >
                                                    </div>
                                                    <div
                                                        v-if="
                                                            record.app_installment_type_id ==
                                                                3
                                                        "
                                                    >
                                                        <ts-checkbox
                                                            v-model="
                                                                record.interval_1_month
                                                            "
                                                            disabled
                                                            >{{
                                                                $t(
                                                                    "customerLoanRequest.interval1Month"
                                                                )
                                                            }}</ts-checkbox
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.installmentTypeDuration"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.app_loan_duration
                                                        ? record.app_loan_duration
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.interestRate"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record
                                                        ? record.app_interest_rate +
                                                          "%"
                                                        : "-"
                                                }}
                                                ({{
                                                    record
                                                        ? record.app_interest_rate_in_word
                                                        : ""
                                                }})
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.adminFeeFirstOpening"
                                                    )
                                                }}៖</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                <div
                                                    class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                                                >
                                                    <label class="tw-ml-16">
                                                        {{
                                                            record.loan_type
                                                                .admin_fee_rate
                                                                ? record
                                                                      .loan_type
                                                                      .admin_fee_rate +
                                                                  "%"
                                                                : ""
                                                        }}
                                                        {{
                                                            $t(
                                                                "customerLoanRequest.ofApprovedLoan"
                                                            )
                                                        }}</label
                                                    >
                                                    {{
                                                        record
                                                            ? formatCurrencyWithCode(
                                                                  record.admin_fee,
                                                                  record.currency
                                                              )
                                                            : "-"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.creditCheckFee"
                                                    )
                                                }}
                                                (CBC)៖</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record
                                                        ? formatCurrencyWithCode(
                                                              record.cbc_fee,
                                                              record.currency
                                                          )
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.disbursementDate"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.app_disbursement_date
                                                        ? record.app_disbursement_date
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6 col-md-12">
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.purpose"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.purpose
                                                        ? locale == "kh"
                                                            ? record.purpose
                                                                  .purpose_kh
                                                            : record.purpose
                                                                  .purpose_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.loanType"
                                                    )
                                                }}</label
                                            >
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.loan_type
                                                        ? locale == "kh"
                                                            ? record.loan_type
                                                                  .loan_type_kh
                                                            : record.loan_type
                                                                  .loan_type_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.groupId"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.group_id
                                                        ? record.group_id
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.approveStatus"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                <span
                                                    class="badge bg-info"
                                                    v-if="
                                                        record.approve_status
                                                            .approve_status_id ==
                                                            0
                                                    "
                                                    >{{
                                                        record.approve_status
                                                            .approve_status
                                                    }}</span
                                                >
                                                <span
                                                    class="badge bg-warning"
                                                    v-if="
                                                        record.approve_status
                                                            .approve_status_id ==
                                                            1
                                                    "
                                                    >{{
                                                        record.approve_status
                                                            .approve_status
                                                    }}</span
                                                >
                                                <span
                                                    class="badge bg-success"
                                                    v-if="
                                                        record.approve_status
                                                            .approve_status_id ==
                                                            2
                                                    "
                                                    >{{
                                                        record.approve_status
                                                            .approve_status
                                                    }}</span
                                                >
                                                <span
                                                    class="badge bg-danger"
                                                    v-if="
                                                        record.approve_status
                                                            .approve_status_id ==
                                                            3
                                                    "
                                                    >{{
                                                        record.approve_status
                                                            .approve_status
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.approvedBy"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.approved_by
                                                        ? record.approved_by
                                                              .employee
                                                            ? record.approved_by
                                                                  .employee
                                                                  .employee_name_en
                                                            : record.approved_by
                                                                  .user_name
                                                        : ""
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.comments"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.approval_comment
                                                        ? record.approval_comment
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <label
                                                class="col-md-4 form-col-label control-label tw-text-right"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.firstInstallmentDate"
                                                    )
                                                }}
                                            </label>
                                            <div class="col-md-7 text-blue">
                                                {{
                                                    record.app_first_installment_date
                                                        ? record.app_first_installment_date
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <!-- end b-card -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "show",
    data() {
        return {
            manPhoto: require("@/assets/staff-default-man.png"),
            womenPhoto: require("@/assets/staff-default-women.png")
        };
    },
    computed: {
        ...mapGetters(["formatCurrencyWithCode"]),
        record() {
            return this.$store.state.creditOperation.customerLoanRequest
                .edit_data;
        },
        locale() {
            return this.$root.$i18n.locale;
        },
        isDataSet() {
            return !isEmpty(this.record);
        }
    },
    methods: {
        photoCus(record) {
            if (record.photo) {
                return record.photo;
            }
            return record.gender == "Male" ? this.manPhoto : this.womenPhoto;
        }
    }
};
</script>

<style scoped>
.cover {
    opacity: 0.2;
}
#cover-image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.section-divider {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    font-size: 16px;
}
.list-group-item {
    border: none !important;
    padding: 5px 0 0 0;
    margin: 0;
    border-top: 1px solid #eee !important;
}
.list-group-item.address {
    border: none !important;
    padding-top: 2px;
}
.text-uppercase {
    text-transform: uppercase !important;
}
#img-frame {
    display: block;
    position: relative;
    margin: -50%;
}
#img-frame img {
    object-fit: contain;
    width: 25%;
    height: 25%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 3px;
    margin: 0 auto;
}
table td {
    vertical-align: middle;
}
::-webkit-scrollbar {
    height: 8px;
    width: 3px; /* Remove scrollbar space */
    background: #ffff; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #e5e7e7;
}
div::-webkit-scrollbar-thumb:hover {
    background: #b7b7b8;
}
</style>
